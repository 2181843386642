<template>
  <div class="hamster-layout">
    <Transition name="fade" mode="out-in" @after-enter="handleAfterLeave">
      <div v-if="isWon" class="hamster-layout--overlay"></div>
    </Transition>
    <div class="hamster-layout__coins">
      <NuxtImg v-for="i in 6" :key="i" :src="coinImg" />
    </div>
    <div class="hamster-layout__content">
      <div class="hamster-layout__content--glow"></div>
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGameStore } from '~/features/hamster/store/game.store';

const gameStore = useGameStore();
const { isWon } = storeToRefs(gameStore);

const coinImg = computed(() => getS3Image('hamster', 'coin'));

const handleAfterLeave = () => {
  const domen = useLinks().domen;
  return navigateTo(`${domen}/giftcase`, { external: true });
};
</script>

<style lang="scss" scoped src="/assets/layouts/hamster.scss" />
